
  .video-button-set {
  
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
    gap: 6px;
    display: flex;
    justify-content: center;
  }
  
  .local-video {
    width: 100%;
  }
  
  .video-button-circle {
    background: gray;
    border: none;
    width:  48px;
    height: 48px;
    border-radius: 100%;
    padding: auto;
    color: white;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.4s ease, background-color 0.4s ease;
  }
  
  .video-button-circle:hover {
    box-shadow: 0 7px 37px -9px white;
  }
  
  .video-button-circle.video-button-toggle.active {
    background: rgb(90, 90, 90);
  }