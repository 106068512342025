@import "./videoButton.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafc;
  color: #595354;
  height: 100vh;
}

.content-container {
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.settings-model {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1003;
  background-color: rgba(0, 0, 0, 0.5);

  justify-content: center;
  align-items: center;

  overflow: hidden;
  position: fixed;
}

.settings-content {
  // min-width: 50%;
  max-width: 480px;
  height: 60vh;
  margin-left: 16px;
  margin-right: 16px;
  z-index: 10001;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);

  overflow: hidden;
  position: fixed;
}

.settings-top {
  flex-direction: row;
  display: flex;
  justify-content: end;
  padding: 20px;
}
.settings-exit-button {
  cursor: pointer;
}
.settings-exit-button :hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.settings-container {
  padding-left: 48px;
  padding-right: 48px;
}

@media screen and (max-width: 768px) {
  .settings-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.form-button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
}
.form-button:hover {
  background-color: rgba(10, 123, 10, 0.75);
}

.video-chat-container {
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  background-size: auto;
  background-position: 50% 50%;
  background-color: black;
  background-repeat: no-repeat;
  background-image: url(https://mtassetstore.ams3.cdn.digitaloceanspaces.com/support3e/1519899328796@2x.png);
}

.video-main {
  flex-grow: 1;
  padding: 50px 30px 50px 30px;
  display: grid;
  flex-direction: row;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;

  grid-template-areas:
    "record . bubbles"
    ". . bubbles"
    "volume controls chat";

  position: relative;

  .video-main-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  z-index: 1000;
  > {
    z-index: 1000;
  }
}

@media screen and (max-width: 768px) {
  .video-main {
    flex-grow: 1;
    padding: 50px 30px 50px 30px;
    display: grid;
    flex-direction: row;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr auto;

    grid-template-areas:
      "chat record bubbles"
      ". . bubbles"
      "controls controls controls";

    position: relative;

    .video-main-container {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.chat-controls {
  grid-area: chat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .chat-controls {
    justify-content: center;
    align-items: flex-start;
  }
}

.controls-controls {
  display: flex;
  grid-area: controls;
  padding: 25px 25px 21.3px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  align-items: flex-end;
}

.user-controls {
  grid-area: bubbles;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
}

.volume-controls {
  display: flex;
  grid-area: volume;
  padding: 25px 25px 21.3px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4px;
}

.record-controls {
  justify-self: center;
  grid-area: record;
  padding: 21px 41px 20px 42px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.4);

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
}

.bottomButtons {
  grid-area: volume;
  padding: 48px 25px 21.3px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.4);
}

.blur-circle-buttons {
  padding: 25px 25px 21.3px;
  border-radius: 75px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  z-index: 1000;
}
.blur-circle-buttons:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 768px) {
  .blur-circle-buttons {
    padding: 12px 12px 9px;
    border-radius: 75px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
  }
}

.blur-square-buttons {
  margin-top: 5px;
  padding: 25px 25px 21.3px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  z-index: 1000;
  position: relative;

  .badge-top {
    position:absolute;
    top:0;
    right: 0;

    background-color: red;
    padding: 4px;
    border-radius: 100px;
    color: white;
    transform: translate(40%,-40%);
  }
  &.red {
    background-color: rgb(255, 3, 3);
  }

  .badge-bottom {
    position:absolute;
    bottom:0;
    right: 0;

    background-color: rgb(255, 255, 255);
    padding: 4px;
    border-radius: 100px;
    color: white;
    transform: translate(40%,-40%);
  }

  
  &.image {
    background-color: rgba(255, 255, 255, 0.8);

    padding: 3px 3px 0px 3px;
    margin-bottom: 6px;
    video {
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
.blur-square-buttons:hover {
  background-color: rgba(255, 255, 255, 0.5);
  &.red {
    background-color: rgb(200, 3, 0.5);
  }
}

@media screen and (max-width: 768px) {
  .blur-square-buttons {
   
    padding: 16px 16px 12px;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
  }
}

.img {
  &.radius {
    border-radius: 10px;
    &.square {
      border-radius: 10px;
    }
    &.circle {
      border-radius: 1000px;
    }
  }
}

.p-10 {
  padding: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.mt-10 {
  padding-top: 10px;
}
.side-menu-top-left {
padding: 16px;
position: absolute;
cursor: pointer;

}

.side-menu {
  background-color: #edf0f6;
  width: 0;
  position: relative;
  transition: width 0.6s ease, box-shadow 0.8s ease;
  box-shadow: 0px 0px 9px 8px rgba(0, 0, 0, 0);
  z-index: 1000;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .chat-container {
    overflow-y: scroll;
  }
  &.active {
    flex-shrink: 0;
    width: 445px; //connected to chatBox css
    box-shadow: 0px 0px 9px 8px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .side-menu {
    background-color: #edf0f6;
    width: 0;
    transition: width 0.6s ease, box-shadow 0.8s ease;
    box-shadow: 0px 0px 9px 8px rgba(0, 0, 0, 0);
    z-index: 1000;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    // &.active {
    //   display: none;
    // }
  }
}

.talk-info {
  font-weight: 500;
  font-size: 21px;
}

.remote-video {
  border: 1px solid #cddfe7;
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.local-container {
  position: absolute;
  border: 1px solid #cddfe7;
  // transition: bottom .4s ease;
  z-index: 199900;
  bottom: calc(20px + env(safe-area-inset-bottom));
  right: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  background-color: black;
  border-radius: 5px;
  width: 250px;
  transition: width 0.4s ease;
}

// .local-container:hover {
//   width: 300px;
// }

.container {
  width: 100%;
  height: 100%;
}

.video-container {
  display: grid;
  flex-grow: 100;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  background-color: #595354;
  overflow-y: scroll;
  padding-bottom: 240px;

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

@media screen and (max-width: 900px) {
  .video-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .video-container {
    grid-template-columns: 1fr;
  }
}

.peer {
  & {
    justify-self: stretch;
    position: relative;
    // transition: 2s;
    display: block;
    min-height: 240px;
    min-width: 240px;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    z-index: 10;
  }

  &.active {
    box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.5);
  }

  &:hover {
    z-index: 800;
    // transform: scale(1.05);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }

  .content {
    display: block;
    width: 100%;
    height: 100%;
    background-color: black;
  }

  .button-group {
    position: absolute;
    top: 8px;
    left: 4px;
    border-radius: 4px;
    padding: 8px;
    margin: 0;
    display: flex-inline;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: flex-start;
    align-content: stretch;
    gap: 1px;
    background-color: white;

    button {
      border: none;
      margin: 0;
      padding: 0;
      font-size: 12pt;
      background: none;
      width: 24px;
    }
  }

  &:hover .button-group {
    display: block;
  }

  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    margin: 0;
    color: red !important;
  }
}

.chat-message-card {
  padding: 5px 16px;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  &.my-message {
    justify-content: flex-end;
  }

  p {
    padding-left: 15px;
    padding-right: 15px;
  }

  .message-area {
    background-color: #ffffff;
    padding: 1px;
    border-radius: 10px 10px 10px 0px;

    &.user {
      margin-left: 10px;
      background-color: #cfd3e4;
      padding: 1px;
      border-radius: 10px 10px 0px 10px;
    }
  }
}

.time-right {
  float: right;
  color: rgb(14, 10, 10);
  font-size: 12px;
}

.chatBox {
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  align-items: center;
  // width: 100%;//connected to active sidebar width css
  background-color: #edf0f6;
  padding: 20px;
  gap: 16px;
  border-radius: 10px 10px 0px 0px;
}
.shadow {
  box-shadow: 10px 8px 10px 10px;
}

.custom-chat-textbox {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 350px;
}
.custom-chat-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px 3px 15px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
}
.custom-chat-botton {
  position: absolute;
  right: 3px;
  top: 12px;
  bottom: 3px;
  border: 0;
  background: white;
  outline: none;
  margin: 0;
  padding: 0 10px;
  z-index: 2;
}

.custom-chat-send-button {
  border: 0;
  border-radius: 10px;
  background-color: #8d75f3;
  outline: none;
  margin: 0;
  padding: 10px;
}
.small-window-button {

  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  cursor: pointer;
  padding: 5px;
  backdrop-filter: blur(5px);
}
.small-window-button-left {
  position: absolute;

  top: 10px;
  left: 10px;
  cursor: pointer;
}
.small-window-button-right {
  position: absolute;

  top: 10px;
  right: 10px;
}
.small-window-button-right-bottom {
  position: absolute;
  top: 35%;
  right: 10px;
}

@media screen and (max-width: 900px) {
  .small-window-button-right-bottom {
    top: 35%;
  }
}

@media screen and (max-width: 600px) {
  .small-window-button-right-bottom {
    top: 50%;
  }
}

// .small-window-overlay-title {
//   position:absolute;
//   bottom:0px;
//   background-color: rgba(255, 255, 255, 0.4);
// cursor: pointer;
//   border-radius: 10px;
// }

.small-window-title {
  padding: 0px;
  margin: 0px;
  max-width: 150px; //same camera window
  width: 100%;
  font-size: 10pt;
  position: absolute;
  bottom: 6px;
  left: 6px;
  right: 6px;
  color: white;
  font-weight: bold;
}

.small-window-container {
  position: relative;
  padding: 25px 25px 21.3px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1000;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    &.red {
      background-color: rgb(200, 3, 0.5);
    }
  }
  &.red {
    background-color: rgb(255, 3, 3);
  }
  transition: transform 0.4s ease, background 0.4s ease;
  &.selected {
    // transform: scale(1.03);
    padding: 3px;
  }
  &.image {
    background-color: rgba(255, 255, 255, 0.8);

    width: 150px;
    height: 150px;
    margin-bottom: 16px;
    padding: 0;
    video {
      object-fit: cover;
      border-radius: 10px;
      width: 150px;
      height: 150px;
    }
  }
}

@media screen and (max-width: 768px) {
  .small-window-container.image,
  .blur-square-buttons.image {
    margin-bottom: 6px;
    width: 100px;
    height: 100px;
    video {
      width: 100px;
      height: 100px;
    }
  }
}

//COLORPICKER

.cp-popover {
  position: absolute;
  z-index: 2;
  right: 0
}
.cp-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;


}
@media screen and (max-width: 900px) {
  .cp-popover {
    right: 0;
  }
}

@media screen and (max-width: 600px) {
  .cp-popover {
    left: 0;
  }
}

